import { useState, useEffect, useRef } from 'react';
import Conversation from './components/Conversation';

function App() {
  const [isActive, setIsActive] = useState(false);
  const [isGrid, setIsGrid] = useState(true);
  const [selectedChannel, setSelectedChannel] = useState(null);
  const [audio] = useState(new Audio('/assets/songs/St Chroma.mp3'));
  const [isRecording, setIsRecording] = useState(false);
  const [currentMessage, setCurrentMessage] = useState('');
  const [conversationActive, setConversationActive] = useState(false);
  const [onAir, setOnAir] = useState(false);
  
  const conversationRef = useRef(null);

  const channels = [
    {
      id: 1,
      name: 'HIP HOP'
    },
    // {
    //   id: 2,
    //   name: 'POP'
    // },
    // {
    //   id: 3,
    //   name: 'ROCK'
    // }
  ];

  const selectChannel = (id) => {
    // Reset any existing audio
    audio.pause();
    audio.currentTime = 0;
    
    // Set the initial channel state
    setSelectedChannel({
      id: id,
      song: 'St. Chroma',
      artist: 'Tyler, the Creator',
      offerAvailable: false,
    });

    // Load the audio first
    audio.load();

    // Wait for metadata to load so we can get the duration
    audio.addEventListener('loadedmetadata', () => {
      try {
        audio.currentTime = Math.max(0, audio.duration - 1);
        audio.play();
      } catch (error) {
        console.error('Audio playback failed:', error);
      }
    });

    audio.addEventListener('ended', () => {
      setSelectedChannel(prev => ({
        ...prev,
        offerAvailable: true
      }));
      // Start AI conversation here
      startAIConversation();
    });
  };

  const startAIConversation = () => {
    setConversationActive(true);
  };

  const handleMessageReceived = (message) => {
    setCurrentMessage(message);
  };

  const handleCallClick = async () => {
    if (!isRecording) {
      await conversationRef.current?.startRecording();
      setIsRecording(true);
      setOnAir(true);
    }
  };

  const handlePowerSequence = (poweringOn) => {
    setIsActive(poweringOn);
    setSelectedChannel(null);
    setConversationActive(false);
    setIsGrid(true);
    setCurrentMessage('');
    audio.pause();
    audio.currentTime = 0;
  };

  // Clean up audio listeners when component unmounts
  useEffect(() => {
    return () => {
      audio.pause();
      audio.currentTime = 0;
      audio.removeEventListener('loadedmetadata', () => {});
      audio.removeEventListener('ended', () => {});
    };
  }, [audio]);

  return (
    <div className="flex w-full h-dvh items-center justify-center bg-grey-1">
      <div className="flex items-center justify-center relative w-[940px] h-[490px]">
        <div className="flex items-center justify-center w-[740px] h-[390px] p-2 border border-grey-2 gap-2">
          {/* This section is the display */}
          <div className="flex relative items-center justify-center w-full h-full p-2 border border-grey-2 rounded-[6px]">
            {/* This is the grid display */}
            {isGrid && isActive && (
              <div className="w-full h-full grid grid-cols-4 grid-rows-4 gap-2">
                {[...Array(16)].map((_, index) => {
                  // Only show cell if there's a channel for this index
                  if (index >= channels.length) {
                    return (
                      <div 
                        key={index} 
                        className="flex flex-col bg-[#FEFDF2] p-2"
                      />
                    );
                  }

                  const channel = channels[index];
                  const isSelected = selectedChannel?.id === channel.id;

                  return (
                    <div 
                      key={index} 
                      className="flex bg-[#FEFDF2]"
                    >
                      {isSelected ? (
                        selectedChannel.offerAvailable ? (
                          <div
                            onClick={handleCallClick}
                            className="flex items-center justify-center h-full w-full text-[20px] font-medium text-red uppercase cursor-pointer"
                          >
                            {onAir ? (
                              <>
                                ON AIR
                                <div className="text-[10px] font-medium text-black"></div>
                              </>
                            ) : (
                              <>
                                CALL IN
                                <div className="text-[10px] font-medium text-black"></div>
                              </>
                            )}
                          </div>
                        ) : (
                          <div onClick={() => selectChannel(channel.id)} className="flex flex-col items-center justify-center w-full h-full p-2 cursor-pointer">
                            <div className="text-[13px] font-medium text-red uppercase">{selectedChannel.song}</div>
                            <div className="text-[13px] font-medium text-black uppercase">{selectedChannel.artist}</div>
                          </div>
                        )
                      ) : (
                        <div 
                          onClick={() => selectChannel(channel.id)}
                          className="flex flex-col w-full h-full items-center justify-center gap-2 text-[14px] font-medium text-black cursor-pointer"
                        >
                          <div className="w-[65%] h-[5px] bg-grey-1"></div>
                          {channel.name}
                          <div className="w-[65%] h-[5px] bg-grey-1"></div>
                        </div>
                      )}
                    </div>
                  );
                })}
              </div>
            )}

            {!isActive && (
              <>
                <div className="w-full h-full bg-grey-2"></div>
                {/* Grid lines overlay */}
                <div className="absolute inset-0 grid grid-cols-4 rounded-[6px] overflow-hidden">
                  {[...Array(16)].map((_, index) => (
                    <div 
                      key={index} 
                      className="h-full w-full border-[4px] border-grey-1"
                    ></div>
                  ))}
                </div>
              </>
            )}
          </div>

          {/* This section is the controls */}
          <div className="flex flex-col items-center justify-between min-w-[64px] h-full p-4 gap-5 border border-grey-2 rounded-[6px]">
            {/* This is the power button */}
            <div className="flex flex-col items-center gap-[6px]">
              <div 
                onClick={() => handlePowerSequence(!isActive)} 
                className="h-[24px] w-[24px] border border-grey-2 rounded-full"
              ></div>
              <div className={`h-[6px] w-[6px] ${isActive ? 'bg-green' : 'bg-grey-2'} rounded-full`}></div>
            </div>

            {/* This is the microphone (non-interactive) */}
            <div className="grid grid-cols-2 gap-[6px]">
              {[...Array(10)].map((_, index) => (
                <div 
                  key={index} 
                  className="h-[10px] w-[10px] border border-grey-2 rounded-full"
                ></div>
              ))}
            </div>

            {/* This is the volume (non-interactive) */}
            <div className="flex items-center justify-center w-[22px] h-full border border-grey-2 rounded-full">
              <div className="h-[18px] w-[18px] border border-grey-2 rounded-full"></div>
            </div>
          </div>
        </div>

        {/* These are labels */}
        <div className="absolute left-[818px] top-[79px] flex items-center justify-start gap-2">
          <div className="w-[35px] h-[1px] bg-grey-2"></div>
          <div className="text-caption tracking-body font-medium text-grey-3">POWER</div>
        </div>
        <div className="absolute left-[818px] top-[160px] flex items-center justify-start gap-2">
          <div className="w-[35px] h-[1px] bg-grey-2"></div>
          <div className="text-caption tracking-body font-medium text-grey-3">MICROPHONE</div>
        </div>
        <div className="absolute left-[818px] top-[311px] flex items-center justify-start gap-2">
          <div className="w-[35px] h-[1px] bg-grey-2"></div>
          <div className="text-caption tracking-body font-medium text-grey-3">VOLUME</div>
        </div>

        {/* Hidden conversation component */}
        <Conversation 
          ref={conversationRef}
          isActive={conversationActive}
          onMessageReceived={handleMessageReceived}
          autoStart={conversationActive}
        />
      </div>
     {/* <div onClick={() => {
       if (conversationRef.current) {
         conversationRef.current.stopConversation();
       }
     }}>STOP</div>  */}
    </div>
  );
}

export default App;